<template>
  <v-layout row wrap class="my-5" align-center>
    <v-flex xs10 offset-xs1 sm8 offset-sm2 md6 offset-md3 lg4 offset-lg4>
        <div
          class="font-weight-black text-h4 text-center text-uppercase transition-swing mb-10"
          v-text="'Download Motorpark'"
        ></div>
        <v-row v-if="isLoginVisible">
          <v-col :cols="12">
            <div
              class="transition-swing mb-10"
              v-text="'Pentru a obtine circuitul Motorpark Romania trebuie sa te autentifici cu Discord si sa fii membru pe serverul Racing League Romania.'"
            ></div>
          </v-col>
        </v-row>
        <v-row v-if="isLoginVisible">
          <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 3">
            <v-btn color="primary" @click.native="loginDiscord">
              <v-icon left dark>mdi-discord</v-icon>
              Autentificare Discord
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row v-if="isDownloadLinkVisible">
          <v-col :cols="12">
            <div class="transition-swing mb-10">
              <p>Download-ul va incepe imediat. Daca nu incepe, <a :href="downloadLinkHref" target="_blank">apasati aici</a>.</p>
            </div>
          </v-col>
        </v-row>
    </v-flex>
  </v-layout>
</template>

<script>
  import {mapActions} from 'vuex'
  
  export default {
    pageTitle: 'Download Motorpark',
    
    components: {},
    
    data () {
      return {
        downloadLinkVisible: false,
        downloadLink: null
      }
    },
    
    watch: {},

    computed: {
      downloadLinkHref() {
        return this.downloadLink;
      },

      isDownloadLinkVisible() {
        return this.downloadLinkVisible;
      },

      isLoginVisible() {
        return !this.downloadLinkVisible;
      }
    },

    methods: {
      ...mapActions('app', ['showSnackbar']),

      loginDiscord() {
          window.location = `${process.env.VUE_APP_API_SCHEMA}${process.env.VUE_APP_API_HOST}/download/discord-auth`
      }
    },

    mounted() {
      var errMsg = null;
      switch(this.$route.query.err) {
        case 'AuthFailure': errMsg = "Autentificare esuata"; break;
        case 'NotMember': errMsg = "Utilizatorul nu este membru pe serverul Racing League Romania"; break;
        case 'BadToken': errMsg = "Token invalid, incearca autentificarea din nou"; break;
      }

      if (errMsg) {
          this.showSnackbar({
            timeout: 30000,
            content: errMsg,
            color: "error",
            value: true,
          });
          return;
      }

      const downloadToken = this.$route.query.downloadToken;
      if (typeof downloadToken !== 'undefined') {
        this.downloadLinkVisible = true;
        this.downloadLink = `${process.env.VUE_APP_API_SCHEMA}${process.env.VUE_APP_API_HOST}/download/authenticated-get?token=${downloadToken}`;
        window.open(this.downloadLink, "_blank");
      }
      else {
        this.downloadLinkVisible = false;
      }
    }
  }
</script>
